<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" small @click="addData">Tambah</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Nama Fitur</th>
                    <th>Opsi?</th>
                  </tr>
                </thead>
                <tbody v-if="data.features.length > 0">
                  <tr v-for="(feature, index) in data.features" :key="index">
                    <td>
                      <v-text-field
                        v-model="feature.name"
                        label="Nama"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[v => !!v || 'Nama harus diisi!']"
                      />
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        outlined
                        small
                        @click="
                          selectedDelete = index
                          deleteDialog = true
                        "
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3" class="text-center">Klik tombol tambah terlebih dahulu</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn color="error" small depressed class="mr-2" @click="deleteData">Ya</v-btn>
            <v-btn color="error" outlined depressed small @click="deleteDialog = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    data: Object,
    setAlert: Function,
  },
  data() {
    return {
      selectedDelete: null,
      deleteDialog: false,
    }
  },
  methods: {
    addData() {
      const data = { name: '' }
      this.data.features.push(data)
    },
    deleteData() {
      this.data.features.splice(this.selectedDelete, 1)
      this.deleteDialog = false
    },
  },
}
</script>

<style>
</style>