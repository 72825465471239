<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" small @click="addData">Tambah</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Marketplace</th>
                    <th>Link Pembelian</th>
                    <th>Opsi?</th>
                  </tr>
                </thead>
                <tbody v-if="data.links.length > 0">
                  <tr v-for="(link, index) in data.links" :key="index">
                    <td>
                      <v-select
                        :items="purchaseLinks"
                        item-value="id"
                        item-text="name"
                        label="Tipe Pembelian"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[v => !!v || 'Tipe Pembelian harus diisi!']"
                        v-model="link.purchase_link_id"
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="link.link"
                        label="Link"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[v => !!v || 'Link harus diisi!']"
                      />
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        outlined
                        small
                        @click="
                          selectedDelete = index
                          deleteDialog = true
                        "
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3" class="text-center">Klik tombol tambah terlebih dahulu</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn color="error" small depressed class="mr-2" @click="deleteData">Ya</v-btn>
            <v-btn color="error" outlined depressed small @click="deleteDialog = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    data: Object,
    setAlert: Function,
    purchaseLinks: Array,
  },
  data() {
    return {
      selectedDelete: null,
      deleteDialog: false,
    }
  },
  methods: {
    addData() {
      const data = { purchase_link_id: '', link: '' }
      this.data.links.push(data)
    },
    deleteData() {
      this.data.links.splice(this.selectedDelete, 1)
      this.deleteDialog = false
    },
  },
}
</script>

<style>
</style>