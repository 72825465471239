<template>
  <v-row>
    <v-col cols="9">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="data.name"
                outlined
                dense
                hide-details="auto"
                label="Nama Produk"
                :rules="[v => !!v || 'Nama produk harus diisi!']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="data.price"
                outlined
                dense
                hide-details="auto"
                label="Harga"
                :rules="[v => !!v || 'Harga harus diisi!']"
                onkeydown="return ( event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) 
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9) 
                    || (event.keyCode>34 && event.keyCode<40) 
                    || (event.keyCode==46) )"
                @keyup="changeFormat"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="data.code"
                outlined
                dense
                hide-details="auto"
                label="Kode Produk"
                :rules="[v => !!v || 'Kode Produk harus diisi!']"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="data.product_category_id"
                outlined
                dense
                hide-details="auto"
                :items="categories"
                item-value="id"
                item-text="name"
                label="Kategori Produk"
                :rules="[v => !!v || 'Kategori Produk harus diisi!']"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="data.seo_url"
                outlined
                dense
                hide-details="auto"
                label="SEO Url"
                :rules="[v => !!v || 'SEO Url harus diisi!']"
              />
              <a href="javascript:void(0)" class="ml-1" @click="seoURL">Generate URL</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="data.description"
                outlined
                dense
                hide-details="auto"
                label="Deskripsi Produk"
                :rules="[v => !!v || 'Deskripsi Produk harus diisi!']"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="3">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <span>Size: 1000x1000</span>
              <div v-if="data.thumbnail == ''">
                <v-btn
                  color="primary"
                  block
                  outlined
                  small
                  id="button-upload-thumbnail"
                  @click="openFile('attach_thumbnail')"
                  >Upload Thumbnail</v-btn
                >
                <input
                  type="file"
                  name="attach_thumbnail"
                  id="attach_thumbnail"
                  style="display: none"
                  accept="image/*"
                  @change="changeFile($event, 2048)"
                />
              </div>
              <div v-else>
                <v-img width="100%" :src="`data:image/png;base64,${data.thumbnail}`" />
                <v-btn outlined x-small color="error" class="mt-2" block @click="dialogDeleteThumbnail = true">
                  Hapus Thumbnail
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="data.status"
                outlined
                dense
                hide-details="auto"
                :items="statuses"
                item-value="id"
                item-text="name"
                label="Status"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="dialogDeleteThumbnail" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn color="error" small depressed class="mr-2" @click="deleteThumbnail">Ya</v-btn>
            <v-btn color="error" outlined depressed small @click="dialogDeleteThumbnail = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { seoURL, formatRupiahWithoutPrefix } from '@/utils/index'
export default {
  props: {
    data: Object,
    categories: Array,
    setAlert: Function,
  },
  data() {
    return {
      statuses: [
        {
          id: 1,
          name: 'Aktif',
        },
        {
          id: 0,
          name: 'Tidak Aktif',
        },
        {
          id: 2,
          name: 'Coming Soon',
        },
      ],
      dialogDeleteThumbnail: false,
    }
  },
  methods: {
    seoURL() {
      this.data.seo_url = seoURL(this.data.name)
    },
    openFile(id) {
      document.getElementById(id).click()
    },
    changeFile(event, maxSize = 0) {
      const file = event.target.files[0]
      if (file) {
        const fsize = file.size
        const size = Math.round(fsize / 1024)

        if (maxSize > 0 && size > maxSize) {
          this.setAlert({
            success: false,
            status: true,
            message: 'File terlalu besar!',
          })
          return
        }

        this.data.thumbnail_filename = file.name

        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
          this.data.thumbnail = base64String
        }
        reader.readAsDataURL(file)
      }
    },
    deleteThumbnail() {
      this.data.thumbnail_filename = ''
      this.data.thumbnail = ''
      this.dialogDeleteThumbnail = false
    },
    changeFormat() {
      if (this.data.price != '') {
        this.data.price = formatRupiahWithoutPrefix(parseFloat(this.data.price.replaceAll('.', '')))
      }
    },
  },
}
</script>

<style>
</style>