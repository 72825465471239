<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="9" class="d-flex justify-start align-center"><h2>Tambah Produk</h2></v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-tabs v-model="tab" fixed-tabs style="z-index: 0 !important">
        <v-tab href="#form-information">Informasi</v-tab>
        <v-tab href="#form-banner">Banner</v-tab>
        <v-tab href="#form-spesification">Spesifikasi</v-tab>
        <v-tab href="#form-feature">Fitur</v-tab>
        <v-tab href="#form-link">Link Pembelian</v-tab>
        <v-tab href="#form-link-app">Link Aplikasi</v-tab>
      </v-tabs>
    </v-col>

    <v-col cols="12">
      <v-form ref="form" @submit.prevent="validate">
        <v-row>
          <v-col cols="12">
            <v-tabs-items v-model="tab" style="background-color: transparent !important">
              <v-tab-item value="form-information">
                <Information :data="formData" :categories="categories" :setAlert="setAlert" />
              </v-tab-item>
              <v-tab-item value="form-banner">
                <Banner :data="formData" :setAlert="setAlert" />
              </v-tab-item>
              <v-tab-item value="form-spesification">
                <Spesification :data="formData" :setAlert="setAlert" />
              </v-tab-item>
              <v-tab-item value="form-feature">
                <Feature :data="formData" :setAlert="setAlert" />
              </v-tab-item>
              <v-tab-item value="form-link">
                <PurchaseLink :purchaseLinks="purchaseLinks" :data="formData" :setAlert="setAlert" />
              </v-tab-item>
              <v-tab-item value="form-link-app">
                <AppLink :appLinks="appLinks" :data="formData" :setAlert="setAlert" />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-btn color="primary" type="submit" small :loading="loadingSubmitButton">Simpan</v-btn>
                <v-btn color="primary" outlined small @click="$router.go(-1)" class="ml-3">Kembali</v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiRefresh, mdiFileOutline } from '@mdi/js'
import axios from 'axios'
import { shortlessDateTime } from '@/utils'
import Information from './components/Information.vue'
import Banner from './components/Banner.vue'
import Spesification from './components/Spesification.vue'
import Feature from './components/Feature.vue'
import PurchaseLink from './components/PurchaseLink.vue'
import AppLink from './components/AppLink.vue'

export default {
  components: { Information, Banner, Spesification, Feature, PurchaseLink, AppLink },
  setup() {
    return {
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      tab: null,
      purchaseLinks: [],
      appLinks: [],
      categories: [],
      loadingSubmitButton: false,
      formData: {
        specifications: [],
        features: [],
        links: [],
        app_links: [],
        banners: [],
        name: '',
        code: '',
        price: '',
        description: '',
        status: 1,
        seo_url: '',
        product_category_id: '',
        thumbnail_filename: '',
        thumbnail: '',
        old_thumbnail_url: '',
      },
    }
  },
  mounted() {
    this.getCategories()
    this.getPurchaseLinks()
    this.getAppLinks()
  },
  methods: {
    getPurchaseLinks() {
      const token = localStorage.getItem('token')
      this.purchaseLinks = []
      const params = {
        page: 1,
        limit: 99,
        q: '',
        status: '',
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}purchase-link?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.status == 200) {
            const data = res.data
            if (data.success == 1) {
              this.purchaseLinks = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    getCategories() {
      this.categories = []
      const token = localStorage.getItem('token')
      const params = {
        page: 1,
        limit: 99,
        q: '',
        status: '',
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}product-category?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.categories = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    getAppLinks(){
      const token = localStorage.getItem('token')
      this.appLinks = []
      const params = {
        page: 1,
        limit: 99,
        q: '',
        status: '',
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}app-link?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.status == 200) {
            const data = res.data
            if (data.success == 1) {
              this.appLinks = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    setAlert(alert) {
      this.alert = alert
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (this.formData.links.length == 0) {
          this.alert = {
            status: true,
            success: false,
            message: 'Harap cek semua kolom!',
          }
          return
        }

        if (this.formData.thumbnail == '') {
          this.alert = {
            status: true,
            success: false,
            message: 'Thumbnail harus diisi!',
          }
          return
        }

        for (const banner of this.formData.banners) {
          if (banner.image == '') {
            this.alert = {
              status: true,
              success: false,
              message: 'Gambar banner harus diisi!',
            }
            return
          }
        }

        this.submit()
      } else {
        this.alert = {
          status: true,
          success: false,
          message: 'Harap cek semua kolom!',
        }
      }
    },
    submit() {
      this.loadingSubmitButton = true
      const token = localStorage.getItem('token')
      const data = {
        ...this.formData,
        price: parseFloat(this.formData.price.replaceAll('.', '')),
      }
      axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}product`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingSubmitButton = false
          if (res.status == 200) {
            const data = res.data
            this.alert = {
              status: true,
              success: data.success == 1,
              message: data.message,
            }
            if (data.success) {
              this.$router.go(-1)
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.loadingSubmitButton = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>

<style>
</style>