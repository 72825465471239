<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" small @click="addBanner">Tambah</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Banner</th>
                    <th>Nama</th>
                    <th>Opsi?</th>
                  </tr>
                </thead>
                <tbody v-if="data.banners.length > 0">
                  <tr v-for="(banner, index) in data.banners" :key="index">
                    <td style="width: 300px;">
                      <span>Size: 1681x749</span>
                      <div v-if="banner.image == ''">
                        <v-btn color="primary" outlined small @click="openFile(`upload_banner_${index}`)">
                          Upload Banner
                        </v-btn>
                        <input
                          type="file"
                          style="display: none"
                          :id="`upload_banner_${index}`"
                          accept="image/*"
                          @change="changeFile($event, 2048, index)"
                        />
                      </div>
                      <div v-else>
                        <v-img width="300px" :src="`data:image/png;base64,${banner.image}`" />
                        <v-btn outlined x-small color="error" block class="mt-2" @click="deleteImageBanner(index)"> Hapus </v-btn>
                      </div>
                    </td>
                    <td>
                      <v-text-field
                        v-model="banner.name"
                        label="Nama Banner"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="[v => !!v || 'Nama banner harus diisi!']"
                      />
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        outlined
                        small
                        @click="
                          selectedDelete = index
                          deleteDialog = true
                        "
                        >Hapus</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="3" class="text-center">Klik tombol tambah terlebih dahulu</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn color="error" small depressed class="mr-2" @click="deleteBanner">Ya</v-btn>
            <v-btn color="error" outlined depressed small @click="deleteDialog = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    data: Object,
    setAlert: Function,
  },
  data() {
    return {
      selectedDelete: null,
      deleteDialog: false,
    }
  },
  methods: {
    addBanner() {
      const banner = {
        name: '',
        old_image_url: '',
        image_filename: '',
        image: '',
      }
      this.data.banners.push(banner)
    },
    deleteBanner() {
      this.data.banners.splice(this.selectedDelete, 1)
      this.deleteDialog = false
    },
    openFile(id) {
      document.getElementById(id).click()
    },
    changeFile(event, maxSize = 0, index) {
      const file = event.target.files[0]
      if (file) {
        const fsize = file.size
        const size = Math.round(fsize / 1024)

        if (maxSize > 0 && size > maxSize) {
          this.setAlert({
            success: false,
            status: true,
            message: 'File terlalu besar!',
          })
          return
        }

        this.data.banners[index].image_filename = file.name
        const reader = new FileReader()
        reader.onloadend = () => {
          const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
          this.data.banners[index].image = base64String
        }
        reader.readAsDataURL(file)
      }
    },
    deleteImageBanner(index) {
      this.data.banners[index].image = ''
      this.data.banners[index].image_filename = ''
    }
  },
}
</script>

<style>
</style>